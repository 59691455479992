import React from 'react';
import styled from 'styled-components'
const Heading= styled.h1`
    font-family: Montserrat;
    font-size: 50px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color:rgba(46,52,57,0.9);
    margin-top:${props => props.top || '0px'};
    margin-bottom:${props => props.bottom || '10px'};
    position:relative;
    z-index:10;
    @media screen and (max-width: 479px) {
        font-size: 30px;
        margin-top:${props => props.mobileTop || '0px'} !important;
      }
`;
const SectionHeading = (props) => {
    return (
        <Heading top={props.top} bottom={props.bottom} id={props.id} mobileTop={props.mobileTop}>
            {props.children}
        </Heading>
    );
};

export default SectionHeading;