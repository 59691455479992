import React from 'react';
import styled from 'styled-components'
const Paragraph=styled.p`
    font-family: Roboto;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 25px;
    letter-spacing: normal;
    text-align: left;
    color: #2e3439;
    width:100%;
    opacity:0.8;
    width:${props => props.width? props.width:'auto'};
    margin-bottom:${props => props.bottom? props.bottom:'1.45em'};
    margin-top:${props => props.top? props.top:'10px'};
    @media screen and (max-width: 991px) {
        width:${props => props.mobile? props.mobile:'auto'};
    }
    @media screen and (max-width: 479px) {
        width:100%;
        font-size:16px;
        line-height:20px;
        margin-top:${props => props.mobileTop?props.mobileTop:'10px'};
    }
    & span{
        font-weight:500;
        color:#2e3439;
    }
    & span.blueSpan{
        font-weight:500;
        color:#3c57a1;
    }
`;
const SectionParagraph = (props) => {    
    return (
        <Paragraph mobile={props.mobile} width={props.width} bottom={props.bottom} color={props.color} top={props.top} mobileTop={props.mobileTop}>
            {props.children}
        </Paragraph>
    );
};

export default SectionParagraph;