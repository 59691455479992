import React, { useRef, useEffect, useState } from 'react';
import { Link } from "gatsby"
import axios from "axios";
import styled, { css } from "styled-components"
import { BiCloudUpload } from "@react-icons/all-files/bi/BiCloudUpload";
import SectionButtonExternal from "./SectionButtonExternal"
import Loader from "react-loader-spinner";
import addToMailchimp from 'gatsby-plugin-mailchimp'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
const FormWrap = styled.div`
  width:100%;
  display:flex;
  flex-direction:column;
  height:100%;
  justify-content:flex-start;
  margin-top:70px;
  @media screen and (max-width: 479px) {
    margin-top:40px;
  }
`;
const Row = styled.div`
  width:100%;
  display:flex;
  justify-content:flex-start;
  align-items:center;
  position:relative;
  margin:15px 0 15px 0;
  @media screen and (max-width: 991px) {
    margin:25px 0 25px 0;
  }
`;
const baseInputStyles = css`
  width:100%;
  padding: 0px 12px 0px 12px;
  height:45px;
  border-radius: 5px;
  border: solid 1px rgba(46, 52, 57, 0.5);
  outline:none;
  position:relative;
  display:flex;
  align-items:center;
  justify-content:flex-start;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #abaeb0;
  &:focus {
      outline: none;
      border: solid 1px rgba(46, 52, 57, 1);
  }
`;
const InputField = styled.input`
    ${baseInputStyles}
`;
const TextArea = styled.textarea`
    ${baseInputStyles}
    height:150px;
    padding-top:10px;
    padding-bottom:10px;
    line-height:1.2;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-text-fill-color: #abaeb0;
       -webkit-box-shadow: 0 0 0px 1000px #fff inset;
        transition: background-color 5000s ease-in-out 0s;
    }
    &:internal-autofill-selected{
      background-color:#fff !important;
    }
`;
const FileUploadButton = styled.div`
    ${baseInputStyles}  
`;
const Label = styled.label`
  margin-bottom:0px;
  position:absolute;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: normal;
  text-align: left;
  color: #abaeb0;
  top:-3px;
  left:10px;
  bottom:auto;
  right:auto;
  background:#fff;
  padding:0 5px 0 5px;
  z-index:10;
  ${InputField}:focus ~ & {
      color:rgba(46, 52, 57, 1);
  }
  ${TextArea}:focus ~ & {
    color:rgba(46, 52, 57, 1);
}
`;
const CloudIcon = {
  'fontSize': '30px',
  'opacity': '0.4',
  'color': '#2e3439',
  'marginLeft': 'auto',
};
const ErrorLabel = styled.h5`
  margin-top:5px;
  margin-bottom:0px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: #B00020;
`;
const SuccessLabel = styled(ErrorLabel)`
  color:#4ed964;
  font-size: 20px;
  font-weight:500;
  margin-top:-10vh;
`;
const FormContainer = styled.div`
    position:relative;
    margin:10vh 0 30px auto;
    width:35%;
    display:flex;
    flex-direction:column;
    height:100%;
    justify-content:center;
    padding:0 2.5% 0 2.5%;
    @media screen and (max-width: 991px) {
      margin:20vh 0 30px 0;
      margin-left:0px;
      margin-right:auto;
      width:40%;
    }
    @media screen and (max-width: 767px) {
      margin:0px 0 0px 0;
      width:50%;
    }
    @media screen and (max-width: 479px) {
        margin-bottom:5vh;
        width:100%;
    }
`;
const FormSpinnerOverlay = styled.div`
  position:fixed;
  background:rgba(0,0,0,0.5);
  top:0;
  left:0;
  display:flex;
  justify-content:center;
  align-items:center;
  z-index:1000;
  width:100%;
  height:100%;
`;
const CheckBoxRow = styled(Row)`
    align-items:flex-start;
    width:100%;
    display:flex;
`;
const CheckboxText = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  text-align: left;
  color: #abaeb0;
  margin-left:20px;
  width:95%;
`;
const Checkbox = styled.input`
    ${baseInputStyles}
    width:5%;
    height:auto;
    margin:0;
    padding:0px;
    box-shadow: none;
    appearence:none;
`;
const PrivacyLink = styled.a`
    text-decoration:none;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: left;
    color: #3c57a1;
    margin:0;
`;
const PitchToUsForm = () => {
  const [uploadFileName, setUploadFileName] = useState('Upload File (PDF or PPT)');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [linkedIn, setLinkedIn] = useState('');
  const [businessIdea, setBusinessIdea] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [checkboxState, setCheckboxState] = useState(false);
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [axiosError, setAxiosError] = useState('')
  useEffect(() => {
    setUploadFileName('Upload File (PDF or PPT)');
  }, []);
  const hiddenFileInput = useRef(null);
  const hiddenSubmit = useRef(null);
  const handleChange = event => {
    event.preventDefault();
    if (hiddenFileInput.current.files[0]) {
      setUploadFileName(hiddenFileInput.current.files[0].name)
      setError(false)
    } else {
      setError(true)
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (checkboxState) {
      if (name && hiddenFileInput.current.files[0]) {
        setIsLoading(true)
        const formData = new FormData();
        formData.append('name', name);
        formData.append('file', hiddenFileInput.current.files[0])
        // let file = hiddenFileInput.current.files[0];
        // let newFileName = hiddenFileInput.current.files[0].name;
        setError(false)
        addToMailchimp(email, { FNAME: name, LINKEDIN: linkedIn, COMPANY: companyName, IDEA: businessIdea })
          .then(data => {
            axios.post('https://server.whealthventures.com/upload', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                'X-Api-Key': 'da6b8e6f0b36a3132535d717e916509977f045b4e3c82ce4348dfa7c45c19740cdfb2de1bb6b192376754e5e14936a041eea587a0bd66eaf89dfcf26f6f75804c10608776c58a66ad47036aab1e01947ed784fb42705671de685f06868093afe',
              }
            }).then((res) => {
              if (res.status === 200) {
                setAxiosError(false)
                // show success message component
              }
              else {
                setAxiosError(true)
              }
              setIsLoading(false)
              resetForm()
              setUploadFileName('Upload File (PDF or PPT)')
            })
              .catch((error) => {
                setIsLoading(false)
                setAxiosError(true)
                resetForm()
                setUploadFileName('Upload File (PDF or PPT)')
                // Error
                if (error.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx
                  // console.log(error.response.data);
                  // console.log(error.response.status);
                  // console.log(error.response.headers);
                } else if (error.request) {
                  // The request was made but no response was received
                  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                  // http.ClientRequest in node.js
                  // console.log(error.request);
                } else {
                  // Something happened in setting up the request that triggered an Error
                  // console.log('Error', error.message);
                }
                console.log(error.config);
              });
          })
          .catch(() => {
            setIsLoading(false)
            setAxiosError(true)
            // unnecessary because Mailchimp only ever
            // returns a 200 status code
            // see below for how to handle errors
          })
        resetForm()
      } else {
        setError(true)
      }
    }
    else {
      setError(true)
      setIsLoading(false)
    }
  };
  const resetForm = (e) => {
    setEmail('');
    setName('');
    setCompanyName('');
    setBusinessIdea('');
    setLinkedIn('');
    setUploadFileName('Upload File (PDF or PPT)')
  }
  return (
    <FormContainer>
      <FormWrap>
        <form onSubmit={handleSubmit} style={{ display: axiosError === false ? 'none' : 'block' }}>
          <Row>
            <InputField type="text" value={name} onChange={e => setName(e.target.value)} required autoComplete="new-password"></InputField>
            <Label>Name</Label>
          </Row>
          <Row>
            <InputField type="email" value={email} onChange={e => setEmail(e.target.value)} required autoComplete="new-password"></InputField>
            <Label>Email</Label>
          </Row>
          <Row>
            <InputField type="text" value={linkedIn} onChange={e => setLinkedIn(e.target.value)} required autoComplete="new-password"></InputField>
            <Label>LinkedIn Profle</Label>
          </Row>
          <Row>
            <InputField type="text" value={companyName} onChange={e => setCompanyName(e.target.value)} required autoComplete="new-password"></InputField>
            <Label>Company Name</Label>
          </Row>
          <Row>
            <TextArea required value={businessIdea} onChange={e => setBusinessIdea(e.target.value)} autoComplete="new-password"></TextArea>
            <Label>Business Idea</Label>
          </Row>
          {/* <label>
              Upload File (PDF or PPT):
              <input type='file' ref={fileInput} />
            </label>
            <br /> */}
          <Row style={{ flexDirection: "column", alignItems: "flex-start" }}>
            <FileUploadButton onClick={() => hiddenFileInput.current.click()}>
              {uploadFileName}
              <BiCloudUpload style={CloudIcon}></BiCloudUpload>
            </FileUploadButton>
            <input type='file' style={{ display: 'none' }} onChange={handleChange} ref={hiddenFileInput} accept=".ppt,.pptx,.pdf" />
            {error === '' || !error ? '' : <ErrorLabel>Please choose a file (PDF or PPT)</ErrorLabel>}

          </Row>
          <CheckBoxRow>
            <Checkbox type="checkbox" defaultChecked={false} onChange={e => setCheckboxState(!checkboxState)} required></Checkbox>
            <CheckboxText>I acknowledge that I have read and understood the <PrivacyLink href="/legal" target="_blank">Terms of Use and Privacy Policy</PrivacyLink> and agree with the same</CheckboxText>
          </CheckBoxRow>
          <Row>
            <input type='submit' value='submit' ref={hiddenSubmit} style={{ display: 'none' }} />
            <SectionButtonExternal left={"auto"} right={"0"} top={"10px"} onClick={() => hiddenSubmit.current.click()}>Submit</SectionButtonExternal>
          </Row>
        </form>
        {axiosError === '' ? '' : axiosError === false ? <SuccessLabel color={"#4ed964"}>Thank you for submitting</SuccessLabel> : <ErrorLabel>Something went wrong. Please try again.</ErrorLabel>}
      </FormWrap>
      {isLoading ?
        <FormSpinnerOverlay>
          <Loader
            type="Bars"
            color="#fff"
            height={100}
            width={100}
            timeout={3000} //3 secs
          />
        </FormSpinnerOverlay>
        : ''
      }
    </FormContainer>

  );
};

export default PitchToUsForm;