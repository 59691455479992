import React from 'react';
import styled from "styled-components"
import SectionHeading from "../components/SectionHeading"
import LogoBlock from "../components/LogoBlock"
import Background from "../images/section-background.png"
import SectionParagraph from "../components/SectionParagraph"
import PitchToUsForm from '../components/PitchToUsForm';
import { AnchorLink } from "gatsby-plugin-anchor-links";
const BannerSection=styled.div`
    display:flex;
    flex-direction:row;
    width:100%;
    height:708px;
    position:relative;
    z-index:0;
    justify-content:flex-start;
    margin-bottom:50px;
    @media screen and (max-width: 991px) {
        height:auto;
    }
    @media screen and (max-width: 767px) {
        margin-bottom:0px;
        flex-direction:column;
        height:auto;
    }
    @media screen and (max-width: 479px) {      
       
    }
    `;
const BannerContentWrap=styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:flex-start;
    width:60%;
    padding:0 10% 0 2.5%;
    height:100%;
    @media screen and (max-width: 991px) {
        padding:0 5% 0 2.5%;
    }
    @media screen and (max-width: 767px) {
      width:100%;
      margin-bottom:0px;
    }
`;
const Breadcrumb=styled.h1`
    font-family: Montserrat;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #2e3439;
    margin:20px 0 0px 0;
    @media screen and (max-width: 991px) {
        margin-bottom:10vh;
    }
    @media screen and (max-width: 479px) {
        margin:40px 0 5vh 0;
    }
`;
const BackSection= styled.div`
    position:absolute;
    top:100px;
    left:0%;
    height:100%;
    z-index:-1;
    display: flex;
    flex-direction: column;
    width: 60%;
    background-position:100% 0%;
    opacity:0.8;
    background-image:url(${Background});
    @media screen and (max-width: 479px) {
        width: 100%;
        background-position:35% 0%;
    }
`;
const BannerContentContainer = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
    align-items:flex-start;
    width:100%;
    height:100%;
`;
const TopWrap=styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:flex-start;
    @media screen and (max-width: 767px) {
        margin-top:30px;
    }
`;
const BottomWrap=styled(TopWrap)`
    @media screen and (max-width: 991px) {
        margin-top:50px;
    }
    @media screen and (max-width: 767px) {
        margin-top:10px;
    }
`;
const FAQsection = styled.div`
    display:flex;
    width:100%;
    flex-direction:column;
    align-items:flex-start;
    padding:60px 2.5% 60px 2.5%;
    background-color: #eaf3f9;
    position:relative;
    z-index:1;
    @media screen and (max-width: 991px) {
        z-index:0;
    }
`;
const FAQHeading = styled.h2`
    font-family: Montserrat;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: #2e3439;
    margin:0 0 40px 0;
    @media screen and (max-width: 479px) {
        font-size: 17px;
        margin:0 0 00px 0;
    }
`;
const FAQRow=styled.div`
    display:flex;
    width:100%;
    flex-direction:column;
    align-items:flex-start;
    margin:10px 0 10px 0;
`;
const FAQData = [
    {
        "question":"How to reach out to the team for an investment opportunity?",
        "answer":"Please email us with an updated pitch deck at hello@whealthventures.com",
    },
    {
        "question":"What area does W Health Ventures invest in?",
        "answer":"We invest in technology-enabled start-ups that are improving healthcare delivery.",
    },
    {
        "question":"What areas does W Health Ventures not invest in?",
        "answer":"We do not invest in biotechnology and pharmaceutical companies.",
    },
    {
        "question":"What is the typical ticket size for W Health Ventures?",
        "answer":"We are early-stage investors and invest in Seed and Series A rounds. We invest between USD 1M and USD 5M, depending on the stage of the business. We can do Series A+ investments with like-minded co-investors.",
    },
    {
        "question":"What geographies do you invest in?",
        "answer":"We invest in start-ups based out of the US and India.",
    },
    {
        "question":"What are the investment timelines for W Health Ventures?",
        "answer":" 6 weeks to make an investment decision followed by another 8 weeks for legal and financial diligence and preparing the transaction documents.",
    },
];
const Pitch = (props) => {   
    return (        
        <div>
            <BannerSection>
                <BannerContentWrap>
                    <LogoBlock></LogoBlock>
                    <Breadcrumb>Pitch To Us</Breadcrumb>
                    <BannerContentContainer>
                        <TopWrap>
                            <SectionHeading>We would like to hear from you.</SectionHeading>
                            <SectionParagraph>If you are a founder operating a digital health start-up in the US or in India, please reach out to us through the form.</SectionParagraph>
                        </TopWrap>
                        <BottomWrap>
                            <SectionParagraph bottom={"10px"}>
                                You can also send us an email with your pitch deck at <a href="mailto:hello@whealthventures.com" style={{textDecoration:"none",color:"#3c57a1"}}>hello@whealthventures.com</a>. We read and respond to every pitch within 4 weeks. 
                            </SectionParagraph>
                            <SectionParagraph>
                                Have Doubts? Read the 
                                <AnchorLink to="/pitch-to-us#FAQ" className="anchor" style={{textDecoration:"none"}}>
                                    <span className="blueSpan"> FAQ's</span>
                                </AnchorLink>
                            </SectionParagraph>
                        </BottomWrap>
                        
                    </BannerContentContainer>                    
                </BannerContentWrap>
                <BackSection></BackSection>
                <PitchToUsForm></PitchToUsForm>
            </BannerSection>
            <FAQsection id="FAQ">
                <FAQHeading>Frequently Asked Questions</FAQHeading>
                {FAQData.map((row, index) => (                 
                    <FAQRow key={index}>
                        <SectionParagraph color={"#2e3439"} bottom={"7px"} top={"0px"}><span>Q: {row.question}</span></SectionParagraph>
                        <SectionParagraph color={"rgba(46,52,57,0.8)"} bottom={"0px"} top={"0px"}><span>A: </span> {row.answer}</SectionParagraph>
                    </FAQRow>
                ))}
            </FAQsection>
        </div>
    );
};

export default Pitch;