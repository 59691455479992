import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Pitch from "../views/Pitch"
import Favicon from "../images/w-health-favicon.png"
const PitchToUs = (props) => (
  <Layout>
    <Seo title="Pitch to Us" url="https://whealthventures.com/pitch-to-us" image={Favicon} />
    <Pitch></Pitch>
  </Layout>
)
export default PitchToUs
