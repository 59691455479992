import React from 'react';
import styled from 'styled-components'
const ButtonLink = styled.div`
    text-decoration:none;
    outline:none;
    margin-top:${props => props.top || '0px'};
    margin-left:${props => props.left || '0px'};
    margin-right:${props => props.right || '0px'};
    margin-bottom:${props => props.bottom || '0px'};
    position:relative;
    z-index:3;
`;
const ButtonWrap=styled.div`
    outline:none;
    border:none;
    background:transparent;
}
`;
const ButtonOverlay=styled.div`
    padding: ${props => props.padding || '8px 33px 8px 33px'};
    border-radius:30px;
    border: solid 1px #3c57a1;
    font-family: Roboto;
    font-size: ${props => props.fontSize || '16px'};
    font-weight: 300;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: center;
    color: #3c57a1;
    position:relative;
    background: linear-gradient(to left, transparent 50%, #3c57a1 50%) right;
    background-size: 205%;
    transition: all .5s ease-out;
    cursor:pointer;
    &:hover{
        background-position: left bottom;
        color:#fff;
    }
    @media screen and (max-width: 479px){
        padding: 4px 25px 4px 25px;
    }
`;
const SectionButtonExternal = (props) => {
    return (
        <ButtonLink top={props.top} left={props.left} right={props.right} bottom={props.bottom} onClick={props.onClick}>
            <ButtonWrap>
                <ButtonOverlay padding={props.padding} fontSize={props.fontSize}>
                    {props.children}
                </ButtonOverlay>
            </ButtonWrap>
        </ButtonLink>        
    );
};

export default SectionButtonExternal;